/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
var $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
$(document).ready(function () {

    // $('#main-navigation ul li').removeClass('active');
    // $('#main-navigation ul li:nth-child(2)').addClass('active');

    $(".has-mailing-address").on("change", function () {
        businessMaillingAddress();
    });
    var checkbox = $('.checkbox').find('.iCheck-helper');
    $(checkbox).on('click', function () {
        businessMaillingAddress();
        refundCheckbox();
    });

    function businessMaillingAddress() {
        if ($('.has-mailing-address').prop('checked')) {
            $('.mailing-address').closest('.form-group').show();
        } else {
            $('.mailing-address').closest('.form-group').hide();
        }
    }

    businessMaillingAddress();

    function refundCheckbox(elem) {
        if ($('input.refunded').prop('checked')) {
            $('.refunded-reason').closest('.form-group').show();
        } else {
            $('.refunded-reason').closest('.form-group').hide();
        }
    }

    refundCheckbox();


    $('.custom_bool').closest('.checkbox').addClass('custom_space_checkbox');


    $('button[name="btn_update_and_edit"], button[name="btn_update_and_list"]').click(function () {
        if($('#main-navigation').find('.tab_number').length) {
            var liActive = $('#main-navigation > ul').find('.active').index();
            sessionStorage.setItem('currentTab', liActive + 1);
        }
        else{
            sessionStorage.setItem('currentTab', 1);
        }

        if($('#main-navigation').find('.tab_number_fbns').length) {
            var liActive = $('#main-navigation > ul').find('.active').index();
            sessionStorage.setItem('currentFbnsTab', liActive + 1);
        }
        else{
            sessionStorage.setItem('currentFbnsTab', 1);
        }
    });

    activeTab();
    function activeTab() {
        if($('#main-navigation').find('.tab_number').length) {
            var activeNumberTab = sessionStorage.getItem('currentTab');
            if(activeNumberTab == 'null' || activeNumberTab == null){
                activeNumberTab = 1;
            }
            $('#main-navigation ul li').removeClass('active');
            $('#main-navigation ul li:nth-child(' + activeNumberTab + ')').addClass('active');
            $('#main-navigation .tab-content .tab-pane').removeClass('active in');
            $('#main-navigation .tab-content .tab-pane:nth-child(' + activeNumberTab + ')').addClass('active in');
        }
        if($('#main-navigation').find('.tab_number_fbns').length) {
            var activeNumberTab = sessionStorage.getItem('currentFbnsTab');
            if(activeNumberTab == 'null' || activeNumberTab == null){
                activeNumberTab = 1;
            }
            $('#main-navigation ul li').removeClass('active');
            $('#main-navigation ul li:nth-child(' + activeNumberTab + ')').addClass('active');
            $('#main-navigation .tab-content .tab-pane').removeClass('active in');
            $('#main-navigation .tab-content .tab-pane:nth-child(' + activeNumberTab + ')').addClass('active in');
        }
    }
});
